<template>
  <div :key="chartKey">
    <v-row>
      <v-col
        v-if="filterStore.accNumbers.length < 2 && filterStore.accNumbers.length > 0 && filterStore.groupCode"
        cols="12"
        md="12"
        sm="12"
        order="1"
      >
        <mfr-tree-map></mfr-tree-map>
      </v-col>
      <v-col
        v-if="filterStore.accNumbers.length < 2 && filterStore.accNumbers.length > 0 && filterStore.groupCode.length > 0"
        cols="12"
        md="12"
        sm="12"
        order="2"
      >
        <line-chart-per-molecule></line-chart-per-molecule>
      </v-col>
      <!-- <v-col
        cols="12"
        md="6"
        sm="12"
        order="3"
      >
        <analytics-card-sales-by-molecule></analytics-card-sales-by-molecule>
      </v-col>
      <v-col
        cols="12"
        md="12"
        sm="12"
        order="4"
      >
        <analytics-user-table></analytics-user-table>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import { debounce } from 'lodash'
import mfrMarketShare from '@/components/charts/mfrMarketShare.vue'
import StatisticsCard from '@/components/charts/StatisticsCard.vue'
import AnalyticsUserTable from '@/components/tables/AnalyticsUserTable.vue'
import AnalyticsCardSalesByMolecule from '@/components/tables/AnalyticsCardSalesByMolecule.vue'
import { windowAttributes } from '@/util/functions/functionsData'
import { handleResize } from '@/util/functions'
import { chartService, apiService } from '@/api/index'
import { useFilterStore } from '@/stores/FilterStore'
import mfrTreeMap from '@/components/charts/mfrTreeMap.vue'
import MfrHeatMap from '@/components/charts/MfrHeatMap.vue'
import lineChartPerMolecule from '@/components/charts/lineChartPerMolecule.vue'

export default {
  name: 'Molecules',
  components: {
    mfrMarketShare,
    mfrTreeMap,
    AnalyticsUserTable,
    AnalyticsCardSalesByMolecule,
    MfrHeatMap,
    lineChartPerMolecule,
  },
  setup() {
    const selectedPharmacies = ref([])
    const filterStore = useFilterStore()

    return {
      selectedPharmacies,
      filterStore,
    }
  },
  data() {
    return {
      chartKey: 0,
      molecule: [],
      cardHeight: 'auto',
      fullHeight: 'auto',
      windowAttributes,
      handleResize,
      dateRange: [],
      pharmacyListData: [],
      moleculeListData: [],
      dataTable: [],
      icons: {
        mdiMagnify,
      },
    }
  },
  created() {
    window.addEventListener('resize', this.debouncedMatchHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.debouncedMatchHeight)

    // this.$root.$off('mfr-market-share-pie-chart')
    // this.$root.$off('mfr-heat-map')
    // this.$root.$off('refresh-charts')
    // this.$root.$off('mfr-heat-map-filter-by-mfr')
    // this.$root.$off('reset-mfr-heat-map-filter-by-mfr')
  },
  async mounted() {
    await this.matchHeight()
  },
  methods: {
    async matchHeight() {
      this.windowAttributes = await this.handleResize()
      this.cardHeight = ((this.windowAttributes.height - 150) / 2).toString()
      this.fullHeight = (this.windowAttributes.height - 150).toString()
    },
    debouncedMatchHeight: debounce(function () {
      this.matchHeight()
    }, 500),
  },
}
</script>
