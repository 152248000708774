<template>
  <v-card v-if="loaded">
    <!-- Chart -->
    <div :key="chartKey">
      <vue-apex-charts
        height="500px"
        :options="lineChartPerMoleculeOptions"
        :series="data[1]"
        type="line"
      ></vue-apex-charts>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiRefresh } from '@mdi/js'

import { lineChartPerMoleculeOptions } from '@/assets/chartOptions/market'
import { apiService } from '@/api/index'
import { useFilterStore } from '@/stores/FilterStore'

export default {
  name: 'LineChartPerMolecule',
  components: {
    VueApexCharts,
  },

  setup() {
    const filterStore = useFilterStore()

    return {
      filterStore,
      icons: {
        mdiDotsVertical,
        mdiRefresh,
      },
    }
  },
  data() {
    return {
      loaded: false,
      chartKey: 0,
      lineChartPerMoleculeOptions,
      data: [],
    }
  },
  async mounted() {
    this.filterStoreUnsubscribe = this.filterStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'refreshCharts') {
          this.getMongoChartData()
        }
      })
    })
    await this.getMongoChartData()
  },
  methods: {
    async getFilteredData(mfr) {
      const token = await this.$auth.getTokenSilently()
      const body = {
        dateRange: this.filterStore.dateRange,
        accNumbers: this.filterStore.accNumbers,
        groupCode: this.filterStore.groupCode,
        mfr: this.filterStore.mfr,

        chartType: 'stackedMap',
      }
      this.data = await apiService.getData(token, 'lineChartPerMolecule', body)
    },
    filterCharts(config) {
      console.log(config.w.config.labels[config.dataPointIndex].split(' ')[0])

      //   console.log(config)
    },
    async getMongoChartData() {
      this.loaded = false
      const token = await this.$auth.getTokenSilently()
      const body = {
        dateRange: this.filterStore.dateRange,
        accNumbers: this.filterStore.accNumbers,
        groupCode: this.filterStore.groupCode,
        mfr: this.filterStore.mfr,

        chartType: 'lineChartMap',
      }
      this.data = await apiService.getData(token, 'lineChartPerMolecule', body)

      this.lineChartPerMoleculeOptions.xaxis.categories = this.data[0]
      this.loaded = true

      //   const categories = []
      //   for (let i = 0; i < this.data[0].data.length; i++) {
      //     categories.push(this.data[0].data[i].x)
      //   }

      //   this.chartKey += 1
    },
  },
}
</script>
