<template>
  <v-card>
    <v-card-title>
      <p color="primary">
        Manufacturer HeatMap Chart (Different color shades for each series)
      </p>
    </v-card-title>
    <!-- Chart -->
    <div
      v-if="loaded"
      :key="chartKey"
    >
      <vue-apex-charts
        :height="height"
        :options="mfrHeatMapOptions"
        :series="data"
        type="heatmap"
      ></vue-apex-charts>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiRefresh } from '@mdi/js'
import { useFilterStore } from '@/stores/FilterStore'

import { mfrHeatMapOptions } from '@/assets/chartOptions/market'
import { chartService, apiService } from '@/api/index'
// eslint-disable-next-line import/extensions
import { currencyConvert, hslToHex } from '@/util/functions'

export default {
  name: 'MfrHeatMap',
  components: {
    VueApexCharts,
  },
  props: {
    height: {
      type: String,
      default: 'auto',
    },
    dateRange: {
      type: Array,
      default: [],
    },
  },
  setup() {
    const filterStore = useFilterStore()

    return {
      filterStore,
      icons: {
        mdiDotsVertical,
        mdiRefresh,
      },
    }
  },
  data() {
    return {
      loaded: false,
      chartKey: 0,
      mfrHeatMapOptions,
      data: [],
    }
  },
  async mounted() {
    this.$root.$on('reset-mfr-heat-map-filter-by-mfr', () => {
      this.data = []
      this.getMongoChartData()
    })

    this.$root.$on('refresh-charts', () => {
      this.getMongoChartData()
    })

    this.$root.$on('mfr-heat-map-filter-by-mfr', mfr => {
      this.getFilteredData(mfr)
    })
    await this.getMongoChartData()
  },
  methods: {
    async getFilteredData(mfr) {
      const token = await this.$auth.getTokenSilently()
      this.data = await chartService.getChart(token, 'mfrHeatMap', 'heatMap', this.dateRange, [mfr], '$in')
    },
    filterCharts(config) {
      console.log(config.w.config.labels[config.dataPointIndex].split(' ')[0])

      //   console.log(config)
    },
    async getMongoChartData() {
      this.loaded = false
      const token = await this.$auth.getTokenSilently()
      const body = {
        dateRange: this.filterStore.dateRange,
        accNumbers: this.filterStore.accNumbers,
        groupCode: this.filterStore.groupCode,
        mfr: this.filterStore.mfr,

        chartType: 'heatMap',
      }
      const data = await apiService.getData(token, 'mfrHeatMap', body)

      this.data = data.map(row => ({
        ...row,
        data: row.data.map(cell => ({
          ...cell,
          y: cell.y.toFixed(2),
        })),
      }))

      const spectrumDivision = 4
      const ranges = this.getHeatMapRanges(this.data, spectrumDivision)

      mfrHeatMapOptions.plotOptions = {
        heatmap: {
          colorScale: {
            ranges,
          },
        },
      }

      this.loaded = true
    },
    getHeatMapRanges(data, spectrumDivision) {
      const ranges = []
      const listOfValues = data.map(row => row.data.map(cell => cell.y)).flat()

      listOfValues.sort((a, b) => a - b)

      const lowestValue = Math.min(...listOfValues)
      const highestValue = Math.max(...listOfValues) + 0.01 // 0.01 to avoid exact values for heatMaps which turns red

      console.log({
        lowestValue,
        highestValue,
      })

      const valueDivider = (highestValue - lowestValue) / spectrumDivision
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < spectrumDivision; i++) {
        const from = lowestValue + i * valueDivider
        let to = lowestValue + (i + 1) * valueDivider + 1
        if (i + 1 === spectrumDivision) {
          to = highestValue
        }

        ranges.push({
          from,
          to,

          // color: this.hslToHex((i + 1) * (100 / spectrumDivision) * 1.2, 100, 75),
          color: hslToHex((i + 1) * (100 / spectrumDivision) * 1.2, 100, 55),
          name: `${currencyConvert(from)} - ${currencyConvert(to)}`,
        })
      }

      return ranges
    },
  },
}
</script>
