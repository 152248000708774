<template>
  <v-card>
    <v-row class="ma-0">
      <v-col
        cols="12"
        sm="12"
        class="total-profit-chart-col"
      >
        <v-card-title class="pt-2">
          Single Molecule Tree Map by Pharmacy
        </v-card-title>
        <div v-if="loaded">
          <vue-apex-charts
            type="treemap"
            height="500"
            :options="mfrTreeMapOptions"
            :series="chartSeries"
          ></vue-apex-charts>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd, mdiChartBar } from '@mdi/js'
import { apiService } from '@/api/index'
// eslint-disable-next-line object-curly-newline
import { mfrTreeMapOptions } from '@/assets/chartOptions/market'
import { useFilterStore } from '@/stores/FilterStore'

export default {
  name: 'MfrTreeMap',
  components: {
    VueApexCharts,
  },
  setup() {
    const filterStore = useFilterStore()

    return {
      filterStore,
      mfrTreeMapOptions,
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
        mdiChartBar,
      },
    }
  },
  data() {
    return {
      filterStoreUnsubscribe: null,
      dataTable: [],
      chartSeries: [],
      loaded: false,
    }
  },
  destroyed() {
    this.filterStoreUnsubscribe()
    this.$root.$off('filter-molecule')
    this.$root.$off('refresh-charts')
  },
  mounted() {
    this.filterStoreUnsubscribe = this.filterStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'refreshCharts') {
          this.getMongoChartData()
        }
      })
    })
    this.$root.$on('filter-molecule', config => {
      console.log('filter-molecule', config)
      const mol = this.dataTable[config.dataPointIndex].groupCode
      this.filterStore.setGroupCode([mol])
      console.log('mol', mol)
      this.$root.$emit('refresh-bar', [mol])
      this.getMongoChartData()
    })
    this.getMongoChartData()
  },
  methods: {
    async getMongoChartData() {
      this.loaded = false
      const token = await this.$auth.getTokenSilently()

      const body = {
        dateRange: this.filterStore.dateRange,
        accNumbers: this.filterStore.accNumbers,
        groupCode: this.filterStore.groupCode,
        mfr: this.filterStore.mfr,

        // chartType: 'stackedColumnChart',
      }

      //   const pharmList = chartService.getChart(token, 'pharmacyListSelect')
      //   const molList = chartService.getChart(token, 'moleculeList')
      const table = apiService.getData(token, 'mfrTreeMap', body)

      const [dataTable] = await Promise.all([table])

      //   this.mfrTreeMapOptions.xaxis.categories = dataTable[0]
      this.dataTable = dataTable
      this.chartSeries = [
        {
          data: this.dataTable,
        },
      ]

      //   this.chartSeries = [
      //     {
      //       data: this.dataTable,
      //     },
      //   ]
      this.loaded = true
    },
  },
}
</script>
